import { styled } from "@mui/material/styles";
import useResponsive from "src/hooks/useResponsive";
import background from "src/images/background_image.jpg";

const style = {
  backgroundColor: "#f9fafc",
  maxWidth: "100%",
  borderRadius: 8,
};
const RootStyle = styled("div")(({ theme }) => {
  const mdUp = useResponsive("up", "md");

  return (
    mdUp && {
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      ...style,
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundRepeat: "round",
    }
  );
});

export default RootStyle;
