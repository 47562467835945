import { Box, Card, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoginForm from "src/pages/auth/Login/components/MainSection/login";
import Message from "./components/message";
import NoAccountSection from "./components/no-account-section";
import banner from "src/images/autnuon_logo (1).png";
import useResponsive from "src/hooks/useResponsive";
import Image from "src/components/Image";
import Logo from "src/components/Logo";
import GetStartedTitle from "../Header/GetStartedTitle";
const ContentStyle = styled("div")(({ theme }) => {
  const mdUp = useResponsive("up", "md");
  return {
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    minHeight: "93vh",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(12, 0),
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: mdUp && "20px",
    marginTop: "20px",
    marginBottom: "20px",
  };
});

const MainSection = () => {
  return (
    <Container maxWidth="sm">
      <ContentStyle>
        <Message />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: { xs: 5, sm: 2 },
            mt: { xs: 5, sm: 2 },
          }}
        >
          <Logo />
        </Box>

        <ImageCard />
        <LoginForm />

        <NoAccountSection />
        <GetStartedTitle />
      </ContentStyle>
    </Container>
  );
};
const ImageCard = () => {
  const mdUp = useResponsive("up", "md");

  return (
    mdUp && (
      <SectionStyle>
        <Image
          sx={{ width: "100%", height: 220 }}
          visibleByDefault
          disabledEffect
          alt="login"
          src={banner}
        />
      </SectionStyle>
    )
  );
};
const SectionStyle = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 564,
  // display: "flex",
  // flexDirection: "column",
  // justifyContent: "center",
  // margin: theme.spacing(2, 0, 2, 2),
}));

export default MainSection;
