import Page from "src/components/Page";
import MainSection from "./components/MainSection";
import { Container } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import RootStyle from "./root-style";

const Login = () => {
  const isSubAdmin = localStorage.getItem("isSubAdmin") === "true";
  const mdUp = useResponsive("up", "md");

  if (isSubAdmin) {
    const menu_lists = JSON.parse(localStorage.getItem("menu"));
    const [menu] = menu_lists;
    const { path, children } = menu?.items[0] || {};

    if (path.includes("dashboard")) {
      window.location = children[0]?.path;
      return;
    }
    window.location = path;
    return;
  }
  return mdUp ? (
    <Page title="Login">
      <Container maxWidth="lg">
        <RootStyle>
          {/* <Header /> */}
          <MainSection />
        </RootStyle>
      </Container>
    </Page>
  ) : (
    <Page title="Login">
      <RootStyle>
        <MainSection />
      </RootStyle>
    </Page>
  );
};

export default Login;
