import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const SalesFileUpload = Loadable(
  lazy(() => import("src/pages/admin/sales-file-upload/index"))
);

const sales = [
  {
    path: "sales-file-upload",
    children: [{ index: true, element: <SalesFileUpload /> }],
  },
];

export default sales;
