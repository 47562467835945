import { lazy, useMemo } from "react";
import { Navigate } from "react-router";
import { PLANS } from "src/CONSTANTS";
import PlanConfirm from "src/components/plan-confirm";
import Loadable from "src/routes/Loadable";
import { usePlan } from "src/store/plan";

const BrandSettings = Loadable(
  lazy(() => import("src/pages/admin/settings/brand"))
);
const BusinessSettings = Loadable(
  lazy(() => import("src/pages/admin/settings/business/index"))
);
const NetworkSettings = Loadable(
  lazy(() => import("src/pages/admin/settings/network/index"))
);

const Withdrawal = Loadable(
  lazy(() => import("src/pages/admin/settings/withdrawal/index"))
);

const Currency = Loadable(
  lazy(() => import("src/pages/admin/settings/currency/index"))
);

const Referral = {
  binary: Loadable(
    lazy(() => import("src/pages/admin/settings/network/@pages/referral/index"))
  ),
  nonBinary: Loadable(
    lazy(() => import("src/pages/admin/settings/network/@pages/settings/index"))
  ),
};

const Bonus = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/first/index"))
);

const Level = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/level/index"))
);
const FeeSettings = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/fee/index"))
);
const Bronze = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/binary/index"))
);

const Roi = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/roi/index"))
);
const Binary = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/binary/index"))
);
const Rank = Loadable(
  lazy(() =>
    import("src/pages/admin/settings/network/@pages/new-rank-settings/index")
  )
);
const RankConfiguration = Loadable(
  lazy(() =>
    import(
      "src/pages/admin/settings/network/@pages/rank-settings-configuration/index"
    )
  )
);
const Matrix = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/matrix/index"))
);
const BinaryMatching = Loadable(
  lazy(() =>
    import("src/pages/admin/settings/network/@pages/binaryMatching/index")
  )
);
const MailSettings = Loadable(
  lazy(() => import("src/pages/admin/settings/network/@pages/mail/index"))
);
const WelcomeMail = Loadable(
  lazy(() =>
    import("src/pages/admin/settings/network/@pages/mail/components/welcome")
  )
);
const ProfileUpdate = Loadable(
  lazy(() =>
    import("src/pages/admin/settings/network/@pages/mail/components/profile")
  )
);
const PayoutApprove = Loadable(
  lazy(() =>
    import("src/pages/admin/settings/network/@pages/mail/components/payout")
  )
);
const ForgetPassword = Loadable(
  lazy(() =>
    import("src/pages/admin/settings/network/@pages/mail/components/forget")
  )
);

// const Stair = Loadable(
//   lazy(() => import("src/pages/admin/settings/network/@pages/stair/index"))
// );

const { binary, roi, uniLevel, matrix, monoLine } = PLANS;

const CustomNavigate = () => {
  const plan = usePlan();

  const defaultRoute = useMemo(() => {
    if (plan) {
      switch (plan) {
        case binary: {
          return "binary";
        }

        case uniLevel: {
          return "level";
        }

        case monoLine: {
          return "rank";
        }
        case matrix: {
          return "rank";
        }

        default: {
          return "binary";
        }
      }
    }
    return "binary";
  }, [plan]);

  return <Navigate to={defaultRoute} />;
};

const settings = [
  {
    path: "settings",
    children: [
      { element: <Navigate to="brand" />, index: true },
      { path: "brand", element: <BrandSettings /> },
      {
        path: "business",
        element: <BusinessSettings />,
      },
      {
        path: "network",
        element: <NetworkSettings />,
        children: [
          {
            index: true,
            element: <CustomNavigate />,
          },
          {
            path: "referral",
            element: <Referral.binary />,
          },
          {
            path: "bonus",
            element: <Bonus />,
          },

          {
            path: "level",
            element: <Level />,
          },
          {
            path: "fee",
            element: <FeeSettings />,
          },
          // {
          //   path: "stair",
          //   element: (
          //     <PlanConfirm>
          //       <Stair />
          //     </PlanConfirm>
          //   ),
          // },
          {
            path: "binary",
            element: (
              <PlanConfirm types={[binary, roi]}>
                <Binary />
              </PlanConfirm>
            ),
          },
          {
            path: "binaryMatching",
            element: (
              <PlanConfirm types={[binary, roi]}>
                <BinaryMatching />
              </PlanConfirm>
            ),
          },
          {
            path: "rank",
            element: <Rank />,
          },
          {
            path: "rank-configuration",
            element: <RankConfiguration />,
          },

          {
            path: "bronze",
            element: <Bronze />,
          },
          {
            path: "roi",
            element: (
              <PlanConfirm types={[roi]}>
                <Roi />
              </PlanConfirm>
            ),
          },
          {
            path: "matrix",
            element: (
              <PlanConfirm types={[matrix]}>
                <Matrix />
              </PlanConfirm>
            ),
          },
        ],
      },
      { path: "withdrawal", element: <Withdrawal /> },
      { path: "currency", element: <Currency /> },
      {
        path: "mail-settings",
        element: <MailSettings />,
        children: [
          { index: true, element: <Navigate to="welcome-mail" replace /> },
          { path: "welcome-mail", element: <WelcomeMail /> },
          { path: "profile-update", element: <ProfileUpdate /> },
          { path: "payout-approve", element: <PayoutApprove /> },
          { path: "forget-password", element: <ForgetPassword /> },
        ],
      },
    ],
  },
];

export default settings;
